export default function Index() {
  return (
    <>
      <p className="mb-4">
        We’re excited to be bringing the Buy Michigan Now Festival to{" "}
        <strong>South Lyon</strong>, Aug 1-3, 2025. Please complete the
        appropriate application in its entirety to be considered for exhibit
        space. All applications will be reviewed, and those approved will
        receive an email with instructions on how to complete their reservation.
        Please note that space will NOT be reserved until the reservation
        process is complete, including payment.
      </p>

      <p className="mb-4">
        There are three different applications. Read the definitions below to
        determine the right one.
      </p>

      <h3 className="text-brand-blue text-lg font-black font-header mb-2">
        Vendor
      </h3>
      <p className="mb-2">
        This is the right fit for most applicants. Any businesses or artists
        selling goods or services, other than Concessions should choose this
        category. This includes businesses sampling and selling pre-packaged
        food and beverages like salsa or snacks.{" "}
      </p>
      <p className="mb-6">
        <a href="/vendor" className="text-brand-blue underline">
          Apply as a Vendor
        </a>
      </p>

      <h3 className="text-brand-blue text-lg font-black font-header mb-2">
        Concessions
      </h3>

      <p className="mb-2">
        Companies selling food and/or beverages designed to be consumed by
        guests at the event, should choose this category. Examples include food
        trucks, hot dog stands, ice cream carts, mocktail bars, etc.
      </p>

      <p className="mb-6">
        <a href="/concessionaire" className="text-brand-blue underline">
          Apply as a Concessionaire
        </a>
      </p>

      <h3 className="text-brand-blue text-lg font-black font-header mb-2">
        Nonprofit or Community Group
      </h3>

      <p className="mb-2">
        Organizations requesting a free space courtesy of a BMNFest sponsor to
        create awareness, provide education, or raise funds for a good cause,
        should choose this option. Examples include school clubs and teams,
        scouts, service organizations, or any 501c(3).
      </p>

      <p className="mb-4">
        <a href="/nonprofit" className="text-brand-blue underline">
          Apply as a Nonprofit or Community Group
        </a>
      </p>
    </>
  );
}
